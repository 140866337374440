import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
const Dashboard = React.lazy(() => import("./pages/dashboard"));
const TppDashboard = React.lazy(() => import("./pages/tppDashboard"));
const FieldReport = React.lazy(() => import("./pages/fieldReport"));
const Login = React.lazy(() => import("./pages/login"));
const ViewTppImage = React.lazy(() => import("./pages/viewTppImage"));

function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/tppDashboard" element={<TppDashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/fieldreport" element={<FieldReport />} />
            <Route
              path="/viewTppImage/:fileNameString"
              element={<ViewTppImage />}
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
}

export default App;
