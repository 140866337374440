import { ADD_FEATURE_LIST, GET_FEATURE_LIST } from "./featureList.types";

const INITIAL_STATE = {};

const featureListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_FEATURE_LIST:
      return {
        ...state,
        featured_list: action.payload,
      };

    case GET_FEATURE_LIST:
      return state;

    default:
      return state;
  }
};

export default featureListReducer;
